*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: Inter var, Lato, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

[type="text"], input:where(:not([type])), [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select {
  -webkit-appearance: none;
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"]:focus, input:where(:not([type])):focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
  outline: 2px solid #0000;
}

input::placeholder, textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  text-align: inherit;
  min-height: 1.5em;
}

::-webkit-datetime-edit {
  display: inline-flex;
}

::-webkit-datetime-edit {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-year-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-month-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-day-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-hour-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-minute-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-second-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-millisecond-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

select {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

[multiple], [size]:where(select:not([size="1"])) {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  print-color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  print-color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  color: #2563eb;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid #0000;
}

[type="checkbox"]:checked, [type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

[type="checkbox"]:checked:hover, [type="checkbox"]:checked:focus, [type="radio"]:checked:hover, [type="radio"]:checked:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px solid ButtonText;
  outline: 1px auto -webkit-focus-ring-color;
}

body {
  font-family: Inter var, Lato, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

main a {
  font-weight: 500;
}

p {
  -webkit-hyphens: auto;
  hyphens: auto;
  line-height: 1.5rem;
}

.\@container {
  container-type: size;
}

a[target="_blank"]:before {
  content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22none%22%20viewBox%3D%220%200%2024%2024%22%20stroke-width%3D%221.5%22%20stroke%3D%22currentColor%22%20class%3D%22w-6%20h-6%22%3E%3Cpath%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20d%3D%22M13.5%206H5.25A2.25%202.25%200%200%200%203%208.25v10.5A2.25%202.25%200%200%200%205.25%2021h10.5A2.25%202.25%200%200%200%2018%2018.75V10.5m-10.5%206L21%203m0%200h-5.25M21%203v5.25%22%2F%3E%3C%2Fsvg%3E");
  vertical-align: middle;
  width: 1.25rem;
  height: 1.25rem;
  margin-top: -.25rem;
  margin-left: .25rem;
  margin-right: .125rem;
  display: inline-block;
}

form input, form input[type="checkbox"], form input[type="text"], form input[type="url"], form input[type="email"], form input[type="search"], form input[type="password"], form input[type="time"], form input[type="datetime-local"], form input[type="number"], form textarea, form select {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

form input::placeholder, form input[type="checkbox"]::placeholder, form input[type="text"]::placeholder, form input[type="url"]::placeholder, form input[type="email"]::placeholder, form input[type="search"]::placeholder, form input[type="password"]::placeholder, form input[type="time"]::placeholder, form input[type="datetime-local"]::placeholder, form input[type="number"]::placeholder, form textarea::placeholder, form select::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(156 163 175 / var(--tw-placeholder-opacity));
}

form input:focus, form input[type="checkbox"]:focus, form input[type="text"]:focus, form input[type="url"]:focus, form input[type="email"]:focus, form input[type="search"]:focus, form input[type="password"]:focus, form input[type="time"]:focus, form input[type="datetime-local"]:focus, form input[type="number"]:focus, form textarea:focus, form select:focus {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(147 197 253 / var(--tw-ring-opacity));
}

:is(.dark form input), :is(.dark form input[type="checkbox"]), :is(.dark form input[type="text"]), :is(.dark form input[type="url"]), :is(.dark form input[type="email"]), :is(.dark form input[type="search"]), :is(.dark form input[type="password"]), :is(.dark form input[type="time"]), :is(.dark form input[type="datetime-local"]), :is(.dark form input[type="number"]), :is(.dark form textarea), :is(.dark form select) {
  --tw-border-opacity: 1;
  border-color: rgb(15 23 42 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity));
}

:is(.dark form input)::placeholder, :is(.dark form input[type="checkbox"])::placeholder, :is(.dark form input[type="text"])::placeholder, :is(.dark form input[type="url"])::placeholder, :is(.dark form input[type="email"])::placeholder, :is(.dark form input[type="search"])::placeholder, :is(.dark form input[type="password"])::placeholder, :is(.dark form input[type="time"])::placeholder, :is(.dark form input[type="datetime-local"])::placeholder, :is(.dark form input[type="number"])::placeholder, :is(.dark form textarea)::placeholder, :is(.dark form select)::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(100 116 139 / var(--tw-placeholder-opacity));
}

:is(.dark form input:focus), :is(.dark form input[type="checkbox"]:focus), :is(.dark form input[type="text"]:focus), :is(.dark form input[type="url"]:focus), :is(.dark form input[type="email"]:focus), :is(.dark form input[type="search"]:focus), :is(.dark form input[type="password"]:focus), :is(.dark form input[type="time"]:focus), :is(.dark form input[type="datetime-local"]:focus), :is(.dark form input[type="number"]:focus), :is(.dark form textarea:focus), :is(.dark form select:focus) {
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(37 99 235 / var(--tw-ring-opacity));
}

form input[type="text"], form input[type="url"], form input[type="email"], form input[type="search"], form input[type="password"], form input[type="time"], form input[type="datetime-local"], form input[type="number"], form select, form textarea {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-width: 1px;
  border-radius: .375rem;
  width: 100%;
  display: block;
}

@media (min-width: 640px) {
  form input[type="text"], form input[type="url"], form input[type="email"], form input[type="search"], form input[type="password"], form input[type="time"], form input[type="datetime-local"], form input[type="number"], form select, form textarea {
    font-size: .875rem;
    line-height: 1.25rem;
  }
}

form select {
  cursor: pointer;
  padding-right: 2rem;
}

form input[type="checkbox"] {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(2 132 199 / var(--tw-text-opacity));
  border-radius: .25rem;
  width: 1rem;
  height: 1rem;
}

form input[type="email"] {
  padding-left: 2.5rem;
}

form input:disabled, form textarea:disabled, form select:disabled {
  cursor: not-allowed;
  opacity: .6;
}

form fieldset {
  --tw-border-opacity: 1;
  border-top-width: 1px;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

:is(.dark form fieldset) {
  --tw-border-opacity: 1;
  border-color: rgb(51 65 85 / var(--tw-border-opacity));
}

form legend {
  margin-left: 1rem;
  padding-left: .75rem;
  padding-right: .75rem;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.75rem;
}

form label {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.25rem;
  display: block;
}

:is(.dark form label) {
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity));
}

form .error.error input {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(127 29 29 / var(--tw-text-opacity));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-inset: inset;
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(252 165 165 / var(--tw-ring-opacity));
  border-width: 0;
}

form .error.error input::placeholder {
  --tw-text-opacity: 1;
  color: rgb(252 165 165 / var(--tw-text-opacity));
}

form .error.error input:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-inset: inset;
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(239 68 68 / var(--tw-ring-opacity));
}

button[type="submit"]:not(.secondary-button), form button[type="submit"]:not(.secondary-button), input[type="submit"]:not(.secondary-button), form input[type="submit"]:not(.secondary-button) {
  cursor: pointer;
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-width: 1px;
  border-radius: .375rem;
  justify-content: center;
  padding: .375rem .625rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.25rem;
  text-decoration-line: none;
  display: inline-flex;
}

button[type="submit"]:not(.secondary-button):focus, form button[type="submit"]:not(.secondary-button):focus, input[type="submit"]:not(.secondary-button):focus, form input[type="submit"]:not(.secondary-button):focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-offset-width: 2px;
  outline: 2px solid #0000;
}

button[type="submit"]:not(.secondary-button):focus-visible, form button[type="submit"]:not(.secondary-button):focus-visible, input[type="submit"]:not(.secondary-button):focus-visible, form input[type="submit"]:not(.secondary-button):focus-visible {
  outline-offset: 2px;
  outline: 2px solid #6366f1;
}

button[type="submit"]:not(.secondary-button), form button[type="submit"]:not(.secondary-button), input[type="submit"]:not(.secondary-button), form input[type="submit"]:not(.secondary-button) {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-color: #0000;
}

button[type="submit"]:not(.secondary-button):hover, form button[type="submit"]:not(.secondary-button):hover, input[type="submit"]:not(.secondary-button):hover, form input[type="submit"]:not(.secondary-button):hover {
  --tw-bg-opacity: 1;
  background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}

button[type="submit"]:not(.secondary-button):focus-visible, form button[type="submit"]:not(.secondary-button):focus-visible, input[type="submit"]:not(.secondary-button):focus-visible, form input[type="submit"]:not(.secondary-button):focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(99 102 241 / var(--tw-ring-opacity));
}

:is(.dark button[type="submit"]:not(.secondary-button)), :is(.dark form button[type="submit"]:not(.secondary-button)), :is(.dark input[type="submit"]:not(.secondary-button)), :is(.dark form input[type="submit"]:not(.secondary-button)) {
  --tw-bg-opacity: 1;
  background-color: rgb(2 132 199 / var(--tw-bg-opacity));
}

:is(.dark button[type="submit"]:not(.secondary-button):hover), :is(.dark form button[type="submit"]:not(.secondary-button):hover), :is(.dark input[type="submit"]:not(.secondary-button):hover), :is(.dark form input[type="submit"]:not(.secondary-button):hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(3 105 161 / var(--tw-bg-opacity));
}

:is(.dark button[type="submit"]:not(.secondary-button):focus-visible), :is(.dark form button[type="submit"]:not(.secondary-button):focus-visible), :is(.dark input[type="submit"]:not(.secondary-button):focus-visible), :is(.dark form input[type="submit"]:not(.secondary-button):focus-visible) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(14 165 233 / var(--tw-ring-opacity));
}

.button, form .button, form .button, form form .button, button:not([type="submit"]), form button:not([type="submit"]) {
  cursor: pointer;
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-width: 1px;
  border-radius: .375rem;
  justify-content: center;
  padding: .375rem .625rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.25rem;
  text-decoration-line: none;
  display: inline-flex;
}

.button:focus, form .button:focus, form .button:focus, form form .button:focus, button:not([type="submit"]):focus, form button:not([type="submit"]):focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-offset-width: 2px;
  outline: 2px solid #0000;
}

.button:focus-visible, form .button:focus-visible, form .button:focus-visible, form form .button:focus-visible, button:not([type="submit"]):focus-visible, form button:not([type="submit"]):focus-visible {
  outline-offset: 2px;
  outline: 2px solid #6366f1;
}

.button, form .button, form .button, form form .button, button:not([type="submit"]), form button:not([type="submit"]) {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.button:hover, form .button:hover, form .button:hover, form form .button:hover, button:not([type="submit"]):hover, form button:not([type="submit"]):hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.button:focus-visible, form .button:focus-visible, form .button:focus-visible, form form .button:focus-visible, button:not([type="submit"]):focus-visible, form button:not([type="submit"]):focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(99 102 241 / var(--tw-ring-opacity));
}

:is(.dark .button), :is(.dark form .button), :is(.dark form .button), :is(.dark form form .button), :is(.dark button:not([type="submit"])), :is(.dark form button:not([type="submit"])) {
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity));
  background-color: #ffffff1a;
  border-color: #0000;
}

:is(.dark .button:hover), :is(.dark form .button:hover), :is(.dark form .button:hover), :is(.dark form form .button:hover), :is(.dark button:not([type="submit"]):hover), :is(.dark form button:not([type="submit"]):hover) {
  background-color: #fff3;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

@font-face {
  font-family: Inter var;
  font-weight: 100 900;
  font-style: normal;
  font-named-instance: "Regular";
  font-display: swap;
  src: url("Inter-roman.var.woff2") format("woff2 supports variations(gvar)"), url("Inter-roman.var.woff2") format("woff2-variations"), url("Inter-roman.var.woff2") format("woff2");
}

@font-face {
  font-family: Inter var;
  font-weight: 100 900;
  font-style: italic;
  font-named-instance: "Italic";
  font-display: swap;
  src: url("Inter-italic.var.woff2") format("woff2 supports variations(gvar)"), url("Inter-italic.var.woff2") format("woff2-variations"), url("Inter-italic.var.woff2") format("woff2");
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.prose {
  color: var(--tw-prose-body);
  max-width: 65ch;
}

.prose :where(p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.prose :where([class~="lead"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-lead);
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  font-size: 1.25em;
  line-height: 1.6;
}

.prose :where(a):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-links);
  font-weight: 500;
  text-decoration: underline;
}

.prose :where(strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

.prose :where(a strong):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(blockquote strong):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(thead th strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 1.625em;
  list-style-type: decimal;
}

.prose :where(ol[type="A"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="A" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="I"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="I" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="1"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: decimal;
}

.prose :where(ul):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 1.625em;
  list-style-type: disc;
}

.prose :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *))::marker {
  color: var(--tw-prose-counters);
  font-weight: 400;
}

.prose :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.prose :where(dt):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.25em;
  font-weight: 600;
}

.prose :where(hr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.prose :where(blockquote):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-quotes);
  border-left-width: .25rem;
  border-left-color: var(--tw-prose-quote-borders);
  quotes: "“""”""‘""’";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1em;
  font-style: italic;
  font-weight: 500;
}

.prose :where(blockquote p:first-of-type):not(:where([class~="not-prose"], [class~="not-prose"] *)):before {
  content: open-quote;
}

.prose :where(blockquote p:last-of-type):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
  content: close-quote;
}

.prose :where(h1):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 0;
  margin-bottom: .888889em;
  font-size: 2.25em;
  font-weight: 800;
  line-height: 1.11111;
}

.prose :where(h1 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 900;
}

.prose :where(h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.33333;
}

.prose :where(h2 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 800;
}

.prose :where(h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.6em;
  margin-bottom: .6em;
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.6;
}

.prose :where(h3 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}

.prose :where(h4):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.5em;
  margin-bottom: .5em;
  font-weight: 600;
  line-height: 1.5;
}

.prose :where(h4 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}

.prose :where(img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(picture):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
  display: block;
}

.prose :where(kbd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-kbd);
  box-shadow: 0 0 0 1px rgb(var(--tw-prose-kbd-shadows) / 10%), 0 3px 0 rgb(var(--tw-prose-kbd-shadows) / 10%);
  border-radius: .3125rem;
  padding: .1875em .375em;
  font-family: inherit;
  font-size: .875em;
  font-weight: 500;
}

.prose :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-code);
  font-size: .875em;
  font-weight: 600;
}

.prose :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)):before, .prose :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
  content: "`";
}

.prose :where(a code):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(h1 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(h2 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-size: .875em;
}

.prose :where(h3 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-size: .9em;
}

.prose :where(h4 code):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(blockquote code):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(thead th code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(pre):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  border-radius: .375rem;
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
  padding: .857143em 1.14286em;
  font-size: .875em;
  font-weight: 400;
  line-height: 1.71429;
  overflow-x: auto;
}

.prose :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background-color: #0000;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

.prose :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *)):before, .prose :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
  content: none;
}

.prose :where(table):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  table-layout: auto;
  text-align: left;
  width: 100%;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: .875em;
  line-height: 1.71429;
}

.prose :where(thead):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.prose :where(thead th):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  vertical-align: bottom;
  padding-bottom: .571429em;
  padding-left: .571429em;
  padding-right: .571429em;
  font-weight: 600;
}

.prose :where(tbody tr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.prose :where(tbody tr:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-bottom-width: 0;
}

.prose :where(tbody td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  vertical-align: baseline;
}

.prose :where(tfoot):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-top-width: 1px;
  border-top-color: var(--tw-prose-th-borders);
}

.prose :where(tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  vertical-align: top;
}

.prose :where(figure > *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose :where(figcaption):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-captions);
  margin-top: .857143em;
  font-size: .875em;
  line-height: 1.42857;
}

.prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-kbd: #111827;
  --tw-prose-kbd-shadows: 17 24 39;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-kbd: #fff;
  --tw-prose-invert-kbd-shadows: 255 255 255;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: #00000080;
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

.prose :where(picture > img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose :where(video):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.prose :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: .375em;
}

.prose :where(.prose > ul > li p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose :where(.prose > ul > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose :where(.prose > ul > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose :where(.prose > ol > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose :where(.prose > ol > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose :where(dl):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.prose :where(dd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .5em;
  padding-left: 1.625em;
}

.prose :where(hr + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(h2 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(h3 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(h4 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(thead th:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: 0;
}

.prose :where(thead th:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-right: 0;
}

.prose :where(tbody td, tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding: .571429em;
}

.prose :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: 0;
}

.prose :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-right: 0;
}

.prose :where(figure):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(.prose > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(.prose > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 0;
}

.prose-sm {
  font-size: .875rem;
  line-height: 1.71429;
}

.prose-sm :where(p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.14286em;
  margin-bottom: 1.14286em;
}

.prose-sm :where([class~="lead"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .888889em;
  margin-bottom: .888889em;
  font-size: 1.28571em;
  line-height: 1.55556;
}

.prose-sm :where(blockquote):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.33333em;
  margin-bottom: 1.33333em;
  padding-left: 1.11111em;
}

.prose-sm :where(h1):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: .8em;
  font-size: 2.14286em;
  line-height: 1.2;
}

.prose-sm :where(h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.6em;
  margin-bottom: .8em;
  font-size: 1.42857em;
  line-height: 1.4;
}

.prose-sm :where(h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.55556em;
  margin-bottom: .444444em;
  font-size: 1.28571em;
  line-height: 1.55556;
}

.prose-sm :where(h4):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.42857em;
  margin-bottom: .571429em;
  line-height: 1.42857;
}

.prose-sm :where(img):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-sm :where(picture):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
}

.prose-sm :where(picture > img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose-sm :where(video):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
}

.prose-sm :where(kbd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-radius: .3125rem;
  padding: .142857em .357143em;
  font-size: .857143em;
}

.prose-sm :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .857143em;
}

.prose-sm :where(h2 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .9em;
}

.prose-sm :where(h3 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .888889em;
}

.prose-sm :where(pre):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-radius: .25rem;
  margin-top: 1.66667em;
  margin-bottom: 1.66667em;
  padding: .666667em 1em;
  font-size: .857143em;
  line-height: 1.66667;
}

.prose-sm :where(ol):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-sm :where(ul):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.14286em;
  margin-bottom: 1.14286em;
  padding-left: 1.57143em;
}

.prose-sm :where(li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .285714em;
  margin-bottom: .285714em;
}

.prose-sm :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-sm :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: .428571em;
}

.prose-sm :where(.prose-sm > ul > li p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .571429em;
  margin-bottom: .571429em;
}

.prose-sm :where(.prose-sm > ul > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.14286em;
}

.prose-sm :where(.prose-sm > ul > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.14286em;
}

.prose-sm :where(.prose-sm > ol > li > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.14286em;
}

.prose-sm :where(.prose-sm > ol > li > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.14286em;
}

.prose-sm :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .571429em;
  margin-bottom: .571429em;
}

.prose-sm :where(dl):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.14286em;
  margin-bottom: 1.14286em;
}

.prose-sm :where(dt):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.14286em;
}

.prose-sm :where(dd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .285714em;
  padding-left: 1.57143em;
}

.prose-sm :where(hr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2.85714em;
  margin-bottom: 2.85714em;
}

.prose-sm :where(hr + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-sm :where(h2 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-sm :where(h3 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-sm :where(h4 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-sm :where(table):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .857143em;
  line-height: 1.5;
}

.prose-sm :where(thead th):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-bottom: .666667em;
  padding-left: 1em;
  padding-right: 1em;
}

.prose-sm :where(thead th:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: 0;
}

.prose-sm :where(thead th:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-right: 0;
}

.prose-sm :where(tbody td, tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding: .666667em 1em;
}

.prose-sm :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-left: 0;
}

.prose-sm :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-right: 0;
}

.prose-sm :where(figure):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
}

.prose-sm :where(figure > *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose-sm :where(figcaption):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .666667em;
  font-size: .857143em;
  line-height: 1.33333;
}

.prose-sm :where(.prose-sm > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-sm :where(.prose-sm > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 0;
}

.prose-slate {
  --tw-prose-body: #334155;
  --tw-prose-headings: #0f172a;
  --tw-prose-lead: #475569;
  --tw-prose-links: #0f172a;
  --tw-prose-bold: #0f172a;
  --tw-prose-counters: #64748b;
  --tw-prose-bullets: #cbd5e1;
  --tw-prose-hr: #e2e8f0;
  --tw-prose-quotes: #0f172a;
  --tw-prose-quote-borders: #e2e8f0;
  --tw-prose-captions: #64748b;
  --tw-prose-kbd: #0f172a;
  --tw-prose-kbd-shadows: 15 23 42;
  --tw-prose-code: #0f172a;
  --tw-prose-pre-code: #e2e8f0;
  --tw-prose-pre-bg: #1e293b;
  --tw-prose-th-borders: #cbd5e1;
  --tw-prose-td-borders: #e2e8f0;
  --tw-prose-invert-body: #cbd5e1;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #94a3b8;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #94a3b8;
  --tw-prose-invert-bullets: #475569;
  --tw-prose-invert-hr: #334155;
  --tw-prose-invert-quotes: #f1f5f9;
  --tw-prose-invert-quote-borders: #334155;
  --tw-prose-invert-captions: #94a3b8;
  --tw-prose-invert-kbd: #fff;
  --tw-prose-invert-kbd-shadows: 255 255 255;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #cbd5e1;
  --tw-prose-invert-pre-bg: #00000080;
  --tw-prose-invert-th-borders: #475569;
  --tw-prose-invert-td-borders: #334155;
}

.form-input, .form-textarea, .form-select, .form-multiselect {
  -webkit-appearance: none;
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.form-input:focus, .form-textarea:focus, .form-select:focus, .form-multiselect:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
  outline: 2px solid #0000;
}

.form-input::placeholder, .form-textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

.form-input::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

.form-input::-webkit-date-and-time-value {
  text-align: inherit;
  min-height: 1.5em;
}

.form-input::-webkit-datetime-edit {
  display: inline-flex;
}

.form-input::-webkit-datetime-edit {
  padding-top: 0;
  padding-bottom: 0;
}

.form-input::-webkit-datetime-edit-year-field {
  padding-top: 0;
  padding-bottom: 0;
}

.form-input::-webkit-datetime-edit-month-field {
  padding-top: 0;
  padding-bottom: 0;
}

.form-input::-webkit-datetime-edit-day-field {
  padding-top: 0;
  padding-bottom: 0;
}

.form-input::-webkit-datetime-edit-hour-field {
  padding-top: 0;
  padding-bottom: 0;
}

.form-input::-webkit-datetime-edit-minute-field {
  padding-top: 0;
  padding-bottom: 0;
}

.form-input::-webkit-datetime-edit-second-field {
  padding-top: 0;
  padding-bottom: 0;
}

.form-input::-webkit-datetime-edit-millisecond-field {
  padding-top: 0;
  padding-bottom: 0;
}

.form-input::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

.primary-button, form .primary-button {
  cursor: pointer;
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-width: 1px;
  border-radius: .375rem;
  justify-content: center;
  padding: .375rem .625rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.25rem;
  text-decoration-line: none;
  display: inline-flex;
}

.primary-button:focus, form .primary-button:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-offset-width: 2px;
  outline: 2px solid #0000;
}

.primary-button:focus-visible, form .primary-button:focus-visible {
  outline-offset: 2px;
  outline: 2px solid #6366f1;
}

.primary-button, form .primary-button {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-color: #0000;
}

.primary-button:hover, form .primary-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}

.primary-button:focus-visible, form .primary-button:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(99 102 241 / var(--tw-ring-opacity));
}

:is(.dark .primary-button), :is(.dark form .primary-button) {
  --tw-bg-opacity: 1;
  background-color: rgb(2 132 199 / var(--tw-bg-opacity));
}

:is(.dark .primary-button:hover), :is(.dark form .primary-button:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(3 105 161 / var(--tw-bg-opacity));
}

:is(.dark .primary-button:focus-visible), :is(.dark form .primary-button:focus-visible) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(14 165 233 / var(--tw-ring-opacity));
}

.secondary-button, form .secondary-button {
  cursor: pointer;
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-width: 1px;
  border-radius: .375rem;
  justify-content: center;
  padding: .375rem .625rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.25rem;
  text-decoration-line: none;
  display: inline-flex;
}

.secondary-button:focus, form .secondary-button:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-offset-width: 2px;
  outline: 2px solid #0000;
}

.secondary-button:focus-visible, form .secondary-button:focus-visible {
  outline-offset: 2px;
  outline: 2px solid #6366f1;
}

.secondary-button, form .secondary-button {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.secondary-button:hover, form .secondary-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.secondary-button:focus-visible, form .secondary-button:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(99 102 241 / var(--tw-ring-opacity));
}

:is(.dark .secondary-button), :is(.dark form .secondary-button) {
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity));
  background-color: #ffffff1a;
  border-color: #0000;
}

:is(.dark .secondary-button:hover), :is(.dark form .secondary-button:hover) {
  background-color: #fff3;
}

.red-button, form .red-button {
  cursor: pointer;
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-width: 1px;
  border-radius: .375rem;
  justify-content: center;
  padding: .375rem .625rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.25rem;
  text-decoration-line: none;
  display: inline-flex;
}

.red-button:focus, form .red-button:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-offset-width: 2px;
  outline: 2px solid #0000;
}

.red-button:focus-visible, form .red-button:focus-visible {
  outline-offset: 2px;
  outline: 2px solid #6366f1;
}

.red-button, form .red-button {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-color: #0000;
}

.red-button:hover, form .red-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

:is(.dark .red-button), :is(.dark form .red-button) {
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity));
}

.fade-truncate {
  height: 110px;
  overflow: hidden;
  -webkit-mask-image: linear-gradient(#000 0%, #0000);
  mask-image: linear-gradient(#000 0%, #0000);
}

.finals-score {
  text-align: right;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: flex-end;
  gap: .5rem;
  width: 100%;
  display: grid;
}

@container (aspect-ratio < 1) {
  .finals-score {
    grid-auto-rows: minmax(0, 1fr);
    grid-auto-flow: row;
    margin-top: auto;
  }
}

.select-navigation {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
  border-color: #0000;
  border-radius: .375rem;
  width: 100%;
  margin-top: .25rem;
  padding-left: 3rem;
  display: block;
}

.select-navigation:focus {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

:is(.dark .select-navigation) {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity));
}

:is(.dark .select-navigation:focus) {
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity));
}

.form-row {
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
}

:is(.dark .form-row) {
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity));
}

.form-input[type="checkbox"] {
  --tw-text-opacity: 1;
  color: rgb(30 41 59 / var(--tw-text-opacity));
  padding: .75rem;
}

select.form-input {
  padding-left: .5rem;
  padding-right: 1.25rem;
}

.form-input[disabled] {
  cursor: not-allowed;
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

:is(.dark .form-input[disabled]) {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity));
}

.flat-form-input {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.flat-form-input::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(156 163 175 / var(--tw-placeholder-opacity));
}

.flat-form-input:focus {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(99 102 241 / var(--tw-ring-opacity));
}

:is(.dark .flat-form-input) {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity));
  border-style: none;
}

:is(.dark .flat-form-input)::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(148 163 184 / var(--tw-placeholder-opacity));
}

:is(.dark .flat-form-input:focus) {
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(2 132 199 / var(--tw-ring-opacity));
}

.flat-form-input {
  border-color: #0000;
  border-radius: .375rem;
  margin-top: .25rem;
}

.flat-form-input:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.form-input--error {
  --tw-border-opacity: 1;
  border-color: rgb(252 165 165 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(127 29 29 / var(--tw-text-opacity));
  border-radius: .375rem;
  width: 100%;
  padding-right: 2.5rem;
  display: block;
}

input:not([type="checkbox"]):user-invalid {
  --tw-border-opacity: 1;
  border-color: rgb(252 165 165 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(127 29 29 / var(--tw-text-opacity));
  border-radius: .375rem;
  width: 100%;
  padding-right: 2.5rem;
  display: block;
}

.form-input--error::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(252 165 165 / var(--tw-placeholder-opacity));
}

input:not([type="checkbox"]):user-invalid::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(252 165 165 / var(--tw-placeholder-opacity));
}

.form-input--error:focus {
  --tw-border-opacity: 1;
  border-color: rgb(127 29 29 / var(--tw-border-opacity));
  outline-offset: 2px;
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(239 68 68 / var(--tw-ring-opacity));
  outline: 2px solid #0000;
}

input:not([type="checkbox"]):user-invalid:focus {
  --tw-border-opacity: 1;
  border-color: rgb(127 29 29 / var(--tw-border-opacity));
  outline-offset: 2px;
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(239 68 68 / var(--tw-ring-opacity));
  outline: 2px solid #0000;
}

@media (min-width: 640px) {
  .form-input--error {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  input:not([type="checkbox"]):user-invalid {
    font-size: .875rem;
    line-height: 1.25rem;
  }
}

form .errorlist li {
  --tw-text-opacity: 1;
  color: rgb(127 29 29 / var(--tw-text-opacity));
  margin-top: .5rem;
  margin-bottom: .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
  display: block;
}

:is(.dark form .errorlist li) {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}

.label {
  white-space: nowrap;
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-inset: inset;
  --tw-ring-color: #1d4ed81a;
  border-radius: .375rem;
  align-items: center;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding: .25rem .5rem;
  font-size: .75rem;
  font-weight: 500;
  line-height: 1rem;
  display: inline-flex;
}

:is(.dark .label) {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
  background-color: #9ca3af33;
}

.label + .label {
  margin-top: .25rem;
}

.list-image-check-circle {
  margin-top: 1rem;
  margin-bottom: 1rem;
  list-style-position: outside;
}

.list-image-check-circle > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.list-image-check-circle {
  -webkit-hyphens: auto;
  hyphens: auto;
  text-align: left;
  font-size: 1rem;
  line-height: 1.5rem;
}

.list-image-check-circle li {
  display: flex;
}

.list-image-check-circle li:before {
  content: url("check-circle.svg");
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: .25rem;
  display: block;
}

.menu__block {
  align-items: center;
  display: flex;
}

._menu__item_colors {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

._menu__item_colors:hover {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}

:is(.dark ._menu__item_colors) {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

:is(.dark ._menu__item_colors:hover) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.menu__item {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.menu__item:hover {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}

:is(.dark .menu__item) {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

:is(.dark .menu__item:hover) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.menu__item {
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 60px;
  display: flex;
}

.menu__item > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.menu__item {
  padding: 1rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  text-decoration-line: none !important;
}

@media (min-width: 768px) {
  .menu__item {
    justify-content: center;
    width: auto;
    padding: 0 .5rem;
    font-size: .875rem;
    line-height: 1.25rem;
  }
}

.sortable-ghost {
  opacity: .9;
  background-color: #e0f2fe80;
}

.sortable-drag {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: #e0f2fe1a;
}

.sticky-table {
  width: 100%;
  max-width: 640px;
  font-size: .75rem;
  line-height: 1rem;
  overflow-x: scroll;
}

:is(.dark .sticky-table) {
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity));
}

@media (min-width: 640px) {
  .sticky-table {
    max-width: none;
    font-size: .875rem;
    line-height: 1.25rem;
  }
}

@media (min-width: 1024px) {
  .sticky-table {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.sticky-table th, .sticky-table td {
  padding: .75rem .5rem;
}

@media (min-width: 640px) {
  .sticky-table th, .sticky-table td {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.sticky-table thead th {
  vertical-align: bottom;
  font-weight: 400;
}

.sticky-table thead {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  position: sticky;
  top: 0;
}

:is(.dark .sticky-table thead) {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}

.current_rank td {
  --tw-bg-opacity: 1;
  background-color: rgb(3 105 161 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.tab-navigation a {
  white-space: nowrap;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
  border-color: #0000;
  border-bottom-width: 2px;
  width: 100%;
  padding: 1rem .25rem;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.25rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.tab-navigation a:hover {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

:is(.dark .tab-navigation a) {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity));
}

:is(.dark .tab-navigation a:hover) {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity));
}

.tab-navigation a.current {
  white-space: nowrap;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
  border-color: #0000;
  border-bottom-width: 2px;
  width: 100%;
  padding: 1rem .25rem;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.25rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.tab-navigation a.current:hover {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

:is(.dark .tab-navigation a.current) {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity));
}

:is(.dark .tab-navigation a.current:hover) {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity));
}

.tab-navigation a.current {
  --tw-border-opacity: 1;
  border-bottom-width: 2px;
  border-color: rgb(99 102 241 / var(--tw-border-opacity));
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
  width: 100%;
  padding: 1rem .25rem;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.25rem;
}

:is(.dark .tab-navigation a.current) {
  --tw-border-opacity: 1;
  border-color: rgb(14 165 233 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(14 165 233 / var(--tw-text-opacity));
}

@keyframes rotateIn {
  100% {
    background-position: -270px -9px;
    transform: rotate(0);
  }
}

.confirmation__icon {
  background-image: url("django-bg-features.svg");
  background-position: -360px 201px;
  background-repeat: no-repeat;
  animation: .3s ease-out .5s forwards rotateIn;
}

.message.info {
  --tw-bg-opacity: 1;
  background-color: rgb(153 246 228 / var(--tw-bg-opacity));
}

:is(.dark .message.info) {
  --tw-bg-opacity: 1;
  background-color: rgb(15 118 110 / var(--tw-bg-opacity));
}

.message.success {
  --tw-bg-opacity: 1;
  background-color: rgb(134 239 172 / var(--tw-bg-opacity));
}

:is(.dark .message.success) {
  --tw-bg-opacity: 1;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.message.error {
  --tw-bg-opacity: 1;
  background-color: rgb(248 113 113 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

:is(.dark .message.error) {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

.message.error .error a {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  text-decoration-line: underline;
}

.message.error .error a:hover {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.\!visible {
  visibility: visible !important;
}

.visible {
  visibility: visible;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.-inset-y-px {
  top: -1px;
  bottom: -1px;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.inset-y-1\/2 {
  top: 50%;
  bottom: 50%;
}

.-bottom-\[18px\] {
  bottom: -18px;
}

.-left-3 {
  left: -.75rem;
}

.-right-1 {
  right: -.25rem;
}

.-right-2 {
  right: -.5rem;
}

.bottom-0 {
  bottom: 0;
}

.bottom-28 {
  bottom: 7rem;
}

.bottom-8 {
  bottom: 2rem;
}

.left-0 {
  left: 0;
}

.left-1\/2 {
  left: 50%;
}

.left-4 {
  left: 1rem;
}

.left-\[\.5ch\] {
  left: .5ch;
}

.left-full {
  left: 100%;
}

.right-0 {
  right: 0;
}

.right-\[8\%\] {
  right: 8%;
}

.top-0 {
  top: 0;
}

.top-1\/2 {
  top: 50%;
}

.top-4 {
  top: 1rem;
}

.top-48 {
  top: 12rem;
}

.top-\[12em\] {
  top: 12em;
}

.isolate {
  isolation: isolate;
}

.-z-10 {
  z-index: -10;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.z-\[-1\] {
  z-index: -1;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-span-full {
  grid-column: 1 / -1;
}

.col-start-1 {
  grid-column-start: 1;
}

.col-start-2 {
  grid-column-start: 2;
}

.col-start-5 {
  grid-column-start: 5;
}

.col-end-12 {
  grid-column-end: 12;
}

.col-end-13 {
  grid-column-end: 13;
}

.col-end-4 {
  grid-column-end: 4;
}

.col-end-5 {
  grid-column-end: 5;
}

.col-end-7 {
  grid-column-end: 7;
}

.row-span-2 {
  grid-row: span 2 / span 2;
}

.row-span-3 {
  grid-row: span 3 / span 3;
}

.row-span-full {
  grid-row: 1 / -1;
}

.row-start-1 {
  grid-row-start: 1;
}

.row-start-2 {
  grid-row-start: 2;
}

.row-start-3 {
  grid-row-start: 3;
}

.row-start-\[-2\] {
  grid-row-start: -2;
}

.-m-2 {
  margin: -.5rem;
}

.-m-2\.5 {
  margin: -.625rem;
}

.m-4 {
  margin: 1rem;
}

.m-auto {
  margin: auto;
}

.-mx-2 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.-mx-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-0\.5 {
  margin-top: .125rem;
  margin-bottom: .125rem;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.my-24 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.my-32 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.\!mt-12 {
  margin-top: 3rem !important;
}

.\!mt-5 {
  margin-top: 1.25rem !important;
}

.-mb-12 {
  margin-bottom: -3rem;
}

.-mb-20 {
  margin-bottom: -5rem;
}

.-mb-\[1px\], .-mb-px {
  margin-bottom: -1px;
}

.-ml-0 {
  margin-left: 0;
}

.-ml-0\.5 {
  margin-left: -.125rem;
}

.-ml-1 {
  margin-left: -.25rem;
}

.-ml-px {
  margin-left: -1px;
}

.-mr-0 {
  margin-right: 0;
}

.-mr-0\.5 {
  margin-right: -.125rem;
}

.-mr-14 {
  margin-right: -3.5rem;
}

.-mt-0 {
  margin-top: 0;
}

.-mt-0\.5 {
  margin-top: -.125rem;
}

.-mt-6 {
  margin-top: -1.5rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-1\.5 {
  margin-left: .375rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-9 {
  margin-left: 2.25rem;
}

.ml-auto {
  margin-left: auto;
}

.mr-0 {
  margin-right: 0;
}

.mr-0\.5 {
  margin-right: .125rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-16 {
  margin-right: 4rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-auto {
  margin-right: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-0\.5 {
  margin-top: .125rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-14 {
  margin-top: 3.5rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-auto {
  margin-top: auto;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.aspect-\[2\/3\] {
  aspect-ratio: 2 / 3;
}

.aspect-\[29\/40\] {
  aspect-ratio: 29 / 40;
}

.h-12 {
  height: 3rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: .5rem;
}

.h-2\.5 {
  height: .625rem;
}

.h-24 {
  height: 6rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-8 {
  height: 2rem;
}

.h-9 {
  height: 2.25rem;
}

.h-\[200px\] {
  height: 200px;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-32 {
  max-height: 8rem;
}

.max-h-60 {
  max-height: 15rem;
}

.max-h-\[106px\] {
  max-height: 106px;
}

.max-h-\[120px\] {
  max-height: 120px;
}

.min-h-\[90vh\] {
  min-height: 90vh;
}

.min-h-full {
  min-height: 100%;
}

.min-h-screen {
  min-height: 100vh;
}

.\!w-16 {
  width: 4rem !important;
}

.\!w-\[4ch\] {
  width: 4ch !important;
}

.\!w-\[5ch\] {
  width: 5ch !important;
}

.\!w-\[5em\] {
  width: 5em !important;
}

.\!w-\[fit-content\] {
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.w-0 {
  width: 0;
}

.w-0\.5 {
  width: .125rem;
}

.w-1\/2 {
  width: 50%;
}

.w-11 {
  width: 2.75rem;
}

.w-12 {
  width: 3rem;
}

.w-16 {
  width: 4rem;
}

.w-2 {
  width: .5rem;
}

.w-2\.5 {
  width: .625rem;
}

.w-24 {
  width: 6rem;
}

.w-4 {
  width: 1rem;
}

.w-40 {
  width: 10rem;
}

.w-44 {
  width: 11rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-64 {
  width: 16rem;
}

.w-8 {
  width: 2rem;
}

.w-\[200px\] {
  width: 200px;
}

.w-\[22\.875rem\] {
  width: 22.875rem;
}

.w-\[30vw\] {
  width: 30vw;
}

.w-\[40vw\] {
  width: 40vw;
}

.w-\[80px\] {
  width: 80px;
}

.w-auto {
  width: auto;
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-min {
  width: min-content;
}

.w-px {
  width: 1px;
}

.min-w-0 {
  min-width: 0;
}

.min-w-\[auto\] {
  min-width: auto;
}

.min-w-min {
  min-width: min-content;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-\[10ch\] {
  max-width: 10ch;
}

.max-w-\[160px\] {
  max-width: 160px;
}

.max-w-\[180px\] {
  max-width: 180px;
}

.max-w-\[400px\] {
  max-width: 400px;
}

.max-w-\[900px\] {
  max-width: 900px;
}

.max-w-fit {
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.max-w-full {
  max-width: 100%;
}

.max-w-max {
  max-width: max-content;
}

.max-w-min {
  max-width: min-content;
}

.max-w-none {
  max-width: none;
}

.max-w-prose {
  max-width: 65ch;
}

.max-w-screen-lg {
  max-width: 1024px;
}

.max-w-screen-md {
  max-width: 768px;
}

.max-w-screen-sm {
  max-width: 640px;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-xs {
  max-width: 20rem;
}

.flex-1 {
  flex: 1;
}

.flex-auto {
  flex: auto;
}

.flex-none {
  flex: none;
}

.flex-shrink-0, .shrink-0 {
  flex-shrink: 0;
}

.flex-grow, .grow {
  flex-grow: 1;
}

.basis-44 {
  flex-basis: 11rem;
}

.origin-\[50\%_70\%\] {
  transform-origin: 50% 70%;
}

.origin-bottom-left {
  transform-origin: 0 100%;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-\[125\%\] {
  --tw-translate-x: -125%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-full {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-2 {
  --tw-translate-x: .5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-full {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-2 {
  --tw-translate-y: .5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-3 {
  --tw-rotate: 3deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-6 {
  --tw-rotate: 6deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-\[-20deg\] {
  --tw-rotate: -20deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-\[-3deg\] {
  --tw-rotate: -3deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-\[3deg\] {
  --tw-rotate: 3deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-\[5deg\] {
  --tw-rotate: 5deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.skew-x-\[-30deg\] {
  --tw-skew-x: -30deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.\!cursor-not-allowed {
  cursor: not-allowed !important;
}

.cursor-grab {
  cursor: grab;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.resize {
  resize: both;
}

.scroll-pt-\[100px\] {
  scroll-padding-top: 100px;
}

.list-outside {
  list-style-position: outside;
}

.list-disc {
  list-style-type: disc;
}

.list-none {
  list-style-type: none;
}

.appearance-none {
  -webkit-appearance: none;
  appearance: none;
}

.auto-cols-\[30px\] {
  grid-auto-columns: 30px;
}

.auto-cols-min {
  grid-auto-columns: min-content;
}

.grid-flow-row {
  grid-auto-flow: row;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.grid-flow-col-dense {
  grid-auto-flow: column dense;
}

.auto-rows-min {
  grid-auto-rows: min-content;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-cols-\[85px\,1fr\] {
  grid-template-columns: 85px 1fr;
}

.grid-cols-\[min-content_1fr\] {
  grid-template-columns: min-content 1fr;
}

.grid-cols-\[minmax\(60px\,min-content\)\,1fr\,minmax\(28\%\,min-content\)\] {
  grid-template-columns: minmax(60px, min-content) 1fr minmax(28%, min-content);
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.grid-rows-\[auto\,auto\,1fr\,auto\] {
  grid-template-rows: auto auto 1fr auto;
}

.grid-rows-\[repeat\(3\,fit-content\)\] {
  grid-template-rows: repeat(3, fit-content);
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-items-stretch {
  justify-items: stretch;
}

.gap-1 {
  gap: .25rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-x-14 {
  column-gap: 3.5rem;
}

.gap-x-2 {
  column-gap: .5rem;
}

.gap-x-3 {
  column-gap: .75rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-x-6 {
  column-gap: 1.5rem;
}

.gap-x-8 {
  column-gap: 2rem;
}

.gap-y-1 {
  row-gap: .25rem;
}

.gap-y-1\.5 {
  row-gap: .375rem;
}

.gap-y-32 {
  row-gap: 8rem;
}

.gap-y-5 {
  row-gap: 1.25rem;
}

.gap-y-6 {
  row-gap: 1.5rem;
}

.gap-y-7 {
  row-gap: 1.75rem;
}

.gap-y-8 {
  row-gap: 2rem;
}

.-space-x-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1px * var(--tw-space-x-reverse));
  margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0px * var(--tw-space-x-reverse));
  margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.125rem * var(--tw-space-x-reverse));
  margin-left: calc(.125rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-y-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3rem * var(--tw-space-y-reverse));
}

.space-y-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.space-y-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 1;
}

.divide-x > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-solid > :not([hidden]) ~ :not([hidden]) {
  border-style: solid;
}

.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-divide-opacity));
}

.divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-divide-opacity));
}

.divide-slate-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-divide-opacity));
}

.self-start {
  align-self: flex-start;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.hyphens-none {
  -webkit-hyphens: none;
  hyphens: none;
}

.hyphens-auto {
  -webkit-hyphens: auto;
  hyphens: auto;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.break-all {
  word-break: break-all;
}

.rounded {
  border-radius: .25rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.\!rounded-l-none {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.\!rounded-r-none {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-b {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.rounded-t {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.rounded-tl-xl {
  border-top-left-radius: .75rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-l-4 {
  border-left-width: 4px;
}

.border-r {
  border-right-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-dashed {
  border-style: dashed;
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}

.border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}

.border-gray-900\/40 {
  border-color: #11182766;
}

.border-gray-900\/60 {
  border-color: #11182799;
}

.border-indigo-600 {
  --tw-border-opacity: 1;
  border-color: rgb(79 70 229 / var(--tw-border-opacity));
}

.border-indigo-600\/20 {
  border-color: #4f46e533;
}

.border-purple-600 {
  --tw-border-opacity: 1;
  border-color: rgb(147 51 234 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: #0000;
}

.\!border-l-red-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(239 68 68 / var(--tw-border-opacity)) !important;
}

.\!bg-slate-800 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity)) !important;
}

.bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.bg-gray-900\/5 {
  background-color: #1118270d;
}

.bg-gray-900\/80 {
  background-color: #111827cc;
}

.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity));
}

.bg-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(134 239 172 / var(--tw-bg-opacity));
}

.bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 253 244 / var(--tw-bg-opacity));
}

.bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(224 231 255 / var(--tw-bg-opacity));
}

.bg-indigo-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(238 242 255 / var(--tw-bg-opacity));
}

.bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}

.bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
}

.bg-orange-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 247 237 / var(--tw-bg-opacity));
}

.bg-purple-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 245 255 / var(--tw-bg-opacity));
}

.bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 113 113 / var(--tw-bg-opacity));
}

.bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 242 242 / var(--tw-bg-opacity));
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

.bg-slate-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}

.bg-slate-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}

.bg-slate-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-white\/20 {
  background-color: #fff3;
}

.bg-white\/80 {
  background-color: #fffc;
}

.bg-yellow-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 224 71 / var(--tw-bg-opacity));
}

.bg-none {
  background-image: none;
}

.stroke-gray-400 {
  stroke: #9ca3af;
}

.stroke-gray-900\/10 {
  stroke: #1118271a;
}

.stroke-slate-700 {
  stroke: #334155;
}

.object-cover {
  object-fit: cover;
}

.\!p-1 {
  padding: .25rem !important;
}

.p-1 {
  padding: .25rem;
}

.p-14 {
  padding: 3.5rem;
}

.p-2 {
  padding: .5rem;
}

.p-2\.5 {
  padding: .625rem;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.\!px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-3\.5 {
  padding-left: .875rem;
  padding-right: .875rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.\!pl-0 {
  padding-left: 0 !important;
}

.\!pr-1 {
  padding-right: .25rem !important;
}

.\!pr-1\.5 {
  padding-right: .375rem !important;
}

.\!pr-2 {
  padding-right: .5rem !important;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-32 {
  padding-bottom: 8rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pr-1 {
  padding-right: .25rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-9 {
  padding-right: 2.25rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-14 {
  padding-top: 3.5rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-24 {
  padding-top: 6rem;
}

.pt-32 {
  padding-top: 8rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-8 {
  padding-top: 2rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.\!text-right {
  text-align: right !important;
}

.text-right {
  text-align: right;
}

.align-middle {
  vertical-align: middle;
}

.font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.\!text-xs {
  font-size: .75rem !important;
  line-height: 1rem !important;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-\[0\.8125rem\] {
  font-size: .8125rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-black {
  font-weight: 900;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.not-italic {
  font-style: normal;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-8 {
  line-height: 2rem;
}

.leading-none {
  line-height: 1;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.tracking-wide {
  letter-spacing: .025em;
}

.tracking-wider {
  letter-spacing: .05em;
}

.\!text-black {
  --tw-text-opacity: 1 !important;
  color: rgb(0 0 0 / var(--tw-text-opacity)) !important;
}

.\!text-gray-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(107 114 128 / var(--tw-text-opacity)) !important;
}

.\!text-red-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(220 38 38 / var(--tw-text-opacity)) !important;
}

.\!text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-black\/40 {
  color: #0006;
}

.text-black\/80 {
  color: #000c;
}

.text-blue-400 {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity));
}

.text-blue-700 {
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-400\/90 {
  color: #9ca3afe6;
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-500\/50 {
  color: #6b728080;
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

.text-green-700 {
  --tw-text-opacity: 1;
  color: rgb(21 128 61 / var(--tw-text-opacity));
}

.text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgb(99 102 241 / var(--tw-text-opacity));
}

.text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}

.text-indigo-600\/20 {
  color: #4f46e533;
}

.text-indigo-700 {
  --tw-text-opacity: 1;
  color: rgb(67 56 202 / var(--tw-text-opacity));
}

.text-purple-600 {
  --tw-text-opacity: 1;
  color: rgb(147 51 234 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-red-700 {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
}

.text-red-800 {
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity));
}

.text-slate-300 {
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity));
}

.text-slate-400 {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity));
}

.text-slate-700 {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-white\/40 {
  color: #fff6;
}

.text-white\/80 {
  color: #fffc;
}

.underline {
  text-decoration-line: underline;
}

.line-through {
  text-decoration-line: line-through;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-20 {
  opacity: .2;
}

.opacity-25 {
  opacity: .25;
}

.opacity-30 {
  opacity: .3;
}

.opacity-50 {
  opacity: .5;
}

.opacity-60 {
  opacity: .6;
}

.opacity-75 {
  opacity: .75;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 #0000000d;
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-inset {
  --tw-ring-inset: inset;
}

.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity));
}

.ring-gray-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity));
}

.ring-gray-900\/10 {
  --tw-ring-color: #1118271a;
}

.ring-green-600\/20 {
  --tw-ring-color: #16a34a33;
}

.ring-slate-700\/40 {
  --tw-ring-color: #33415566;
}

.ring-slate-900\/10 {
  --tw-ring-color: #0f172a1a;
}

.ring-white {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity));
}

.ring-white\/10 {
  --tw-ring-color: #ffffff1a;
}

.ring-opacity-5 {
  --tw-ring-opacity: .05;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-xl {
  --tw-drop-shadow: drop-shadow(0 20px 13px #00000008) drop-shadow(0 8px 5px #00000014);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur {
  --tw-backdrop-blur: blur(8px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-linear {
  transition-timing-function: linear;
}

.vertical-lr {
  writing-mode: vertical-lr;
}

[x-cloak] {
  display: none;
}

.participation_qrcode > svg {
  width: 100%;
  height: 100%;
}

.mono-highlight {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
  border-radius: .25rem;
  padding: .1rem .125rem;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.fullscreen .fullscreen\:\@container {
  container-type: size;
}

:is(.dark .dark\:prose-invert) {
  --tw-prose-body: var(--tw-prose-invert-body);
  --tw-prose-headings: var(--tw-prose-invert-headings);
  --tw-prose-lead: var(--tw-prose-invert-lead);
  --tw-prose-links: var(--tw-prose-invert-links);
  --tw-prose-bold: var(--tw-prose-invert-bold);
  --tw-prose-counters: var(--tw-prose-invert-counters);
  --tw-prose-bullets: var(--tw-prose-invert-bullets);
  --tw-prose-hr: var(--tw-prose-invert-hr);
  --tw-prose-quotes: var(--tw-prose-invert-quotes);
  --tw-prose-quote-borders: var(--tw-prose-invert-quote-borders);
  --tw-prose-captions: var(--tw-prose-invert-captions);
  --tw-prose-kbd: var(--tw-prose-invert-kbd);
  --tw-prose-kbd-shadows: var(--tw-prose-invert-kbd-shadows);
  --tw-prose-code: var(--tw-prose-invert-code);
  --tw-prose-pre-code: var(--tw-prose-invert-pre-code);
  --tw-prose-pre-bg: var(--tw-prose-invert-pre-bg);
  --tw-prose-th-borders: var(--tw-prose-invert-th-borders);
  --tw-prose-td-borders: var(--tw-prose-invert-td-borders);
}

.placeholder\:text-gray-400::placeholder {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.after\:absolute:after {
  content: var(--tw-content);
  position: absolute;
}

.after\:-bottom-6:after {
  content: var(--tw-content);
  bottom: -1.5rem;
}

.after\:left-\[2px\]:after {
  content: var(--tw-content);
  left: 2px;
}

.after\:top-0:after {
  content: var(--tw-content);
  top: 0;
}

.after\:top-0\.5:after {
  content: var(--tw-content);
  top: .125rem;
}

.after\:block:after {
  content: var(--tw-content);
  display: block;
}

.after\:h-12:after {
  content: var(--tw-content);
  height: 3rem;
}

.after\:h-5:after {
  content: var(--tw-content);
  height: 1.25rem;
}

.after\:w-5:after {
  content: var(--tw-content);
  width: 1.25rem;
}

.after\:w-full:after {
  content: var(--tw-content);
  width: 100%;
}

.after\:-skew-y-\[1\.5deg\]:after {
  content: var(--tw-content);
  --tw-skew-y: -1.5deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:rounded-full:after {
  content: var(--tw-content);
  border-radius: 9999px;
}

.after\:border:after {
  content: var(--tw-content);
  border-width: 1px;
}

.after\:border-gray-300:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.after\:bg-orange-50:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(255 247 237 / var(--tw-bg-opacity));
}

.after\:bg-white:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.after\:pb-\[100\%\]:after {
  content: var(--tw-content);
  padding-bottom: 100%;
}

.after\:transition-all:after {
  content: var(--tw-content);
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.after\:content-\[\'\'\]:after {
  --tw-content: "";
  content: var(--tw-content);
}

.last\:\!border-b:last-child {
  border-bottom-width: 1px !important;
}

.last\:shadow:last-child {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.odd\:different-bg:nth-child(odd) {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}

:is(.dark .odd\:different-bg:nth-child(odd)) {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}

.first-of-type\:rounded-t-md:first-of-type {
  border-top-left-radius: .375rem;
  border-top-right-radius: .375rem;
}

.last-of-type\:rounded-b-md:last-of-type {
  border-bottom-right-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.empty\:hidden:empty {
  display: none;
}

.focus-within\:outline-none:focus-within {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus-within\:ring-2:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-within\:ring-indigo-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(99 102 241 / var(--tw-ring-opacity));
}

.focus-within\:ring-offset-2:focus-within {
  --tw-ring-offset-width: 2px;
}

.hover\:border-gray-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}

.hover\:border-gray-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.hover\:border-indigo-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(55 48 163 / var(--tw-border-opacity));
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.hover\:bg-indigo-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}

.hover\:bg-indigo-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(67 56 202 / var(--tw-bg-opacity));
}

.hover\:bg-red-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

.hover\:bg-slate-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity));
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.hover\:bg-opacity-90:hover {
  --tw-bg-opacity: .9;
}

.hover\:text-black:hover {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.hover\:text-black\/80:hover {
  color: #000c;
}

.hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.hover\:text-indigo-500:hover {
  --tw-text-opacity: 1;
  color: rgb(99 102 241 / var(--tw-text-opacity));
}

.hover\:text-indigo-600:hover {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}

.hover\:text-indigo-700:hover {
  --tw-text-opacity: 1;
  color: rgb(67 56 202 / var(--tw-text-opacity));
}

.hover\:text-sky-300:hover {
  --tw-text-opacity: 1;
  color: rgb(125 211 252 / var(--tw-text-opacity));
}

.hover\:text-slate-900:hover {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:text-white\/80:hover {
  color: #fffc;
}

.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:ring-gray-900\/20:hover {
  --tw-ring-color: #11182733;
}

.focus\:z-10:focus {
  z-index: 10;
}

.focus\:border-indigo-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(99 102 241 / var(--tw-border-opacity));
}

.focus\:border-white:focus {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-inset:focus {
  --tw-ring-inset: inset;
}

.focus\:ring-indigo-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(99 102 241 / var(--tw-ring-opacity));
}

.focus\:ring-indigo-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(79 70 229 / var(--tw-ring-opacity));
}

.focus\:ring-white:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity));
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.focus-visible\:outline:focus-visible {
  outline-style: solid;
}

.focus-visible\:outline-2:focus-visible {
  outline-width: 2px;
}

.focus-visible\:outline-offset-2:focus-visible {
  outline-offset: 2px;
}

.focus-visible\:outline-indigo-600:focus-visible {
  outline-color: #4f46e5;
}

.group:hover .group-hover\:inline {
  display: inline;
}

.group:hover .group-hover\:bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgb(99 102 241 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-indigo-800 {
  --tw-text-opacity: 1;
  color: rgb(55 48 163 / var(--tw-text-opacity));
}

.peer:checked ~ .peer-checked\:bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

.peer:checked ~ .peer-checked\:after\:translate-x-full:after {
  content: var(--tw-content);
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:checked ~ .peer-checked\:after\:border-white:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.peer:focus ~ .peer-focus\:ring-4 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.peer:focus ~ .peer-focus\:ring-red-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(252 165 165 / var(--tw-ring-opacity));
}

.prose-ol\:list-decimal :where(ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: decimal;
}

.prose-ul\:list-disc :where(ul):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: disc;
}

.fullscreen .fullscreen\:h-\[99vh\] {
  height: 99vh;
}

.fullscreen .fullscreen\:grid-rows-\[min-content\,1fr\] {
  grid-template-rows: min-content 1fr;
}

.fullscreen .fullscreen\:text-\[50cqh\] {
  font-size: 50cqh;
}

.fullscreen .fullscreen\:text-\[clamp\(\.5rem\,60cqh\,1\.5rem\)\] {
  font-size: clamp(.5rem, 60cqh, 1.5rem);
}

.fullscreen .fullscreen\:text-\[clamp\(1rem\,3vh\,2rem\)\] {
  font-size: clamp(1rem, 3vh, 2rem);
}

.fullscreen .fullscreen\:text-\[clamp\(1rem\,6vh\,4rem\)\] {
  font-size: clamp(1rem, 6vh, 4rem);
}

.fullscreen .fullscreen\:leading-none {
  line-height: 1;
}

@media (prefers-reduced-motion: no-preference) {
  .motion-safe\:scroll-smooth {
    scroll-behavior: smooth;
  }
}

:is(.dark .dark\:divide-slate-600) > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(71 85 105 / var(--tw-divide-opacity));
}

:is(.dark .dark\:divide-slate-900) > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(15 23 42 / var(--tw-divide-opacity));
}

:is(.dark .dark\:border-b) {
  border-bottom-width: 1px;
}

:is(.dark .dark\:border-black) {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-gray-500) {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-gray-600) {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-gray-700) {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-gray-900) {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-sky-600) {
  --tw-border-opacity: 1;
  border-color: rgb(2 132 199 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-slate-300) {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-slate-600) {
  --tw-border-opacity: 1;
  border-color: rgb(71 85 105 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-slate-700) {
  --tw-border-opacity: 1;
  border-color: rgb(51 65 85 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-slate-900) {
  --tw-border-opacity: 1;
  border-color: rgb(15 23 42 / var(--tw-border-opacity));
}

:is(.dark .dark\:bg-blue-800) {
  --tw-bg-opacity: 1;
  background-color: rgb(30 64 175 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-cyan-950) {
  --tw-bg-opacity: 1;
  background-color: rgb(8 51 68 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-emerald-600) {
  --tw-bg-opacity: 1;
  background-color: rgb(5 150 105 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-gray-700) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-green-500\/10) {
  background-color: #22c55e1a;
}

:is(.dark .dark\:bg-red-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-sky-600) {
  --tw-bg-opacity: 1;
  background-color: rgb(2 132 199 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-sky-900) {
  --tw-bg-opacity: 1;
  background-color: rgb(12 74 110 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-slate-600) {
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-slate-700) {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-slate-800) {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-slate-800\/60) {
  background-color: #1e293b99;
}

:is(.dark .dark\:bg-slate-900) {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-slate-900\/20) {
  background-color: #0f172a33;
}

:is(.dark .dark\:bg-slate-900\/50) {
  background-color: #0f172a80;
}

:is(.dark .dark\:bg-slate-950) {
  --tw-bg-opacity: 1;
  background-color: rgb(2 6 23 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-slate-950\/20) {
  background-color: #02061733;
}

:is(.dark .dark\:stroke-slate-300) {
  stroke: #cbd5e1;
}

:is(.dark .dark\:text-black) {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-blue-50) {
  --tw-text-opacity: 1;
  color: rgb(239 246 255 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-gray-100) {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-gray-300) {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-gray-400) {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-green-400) {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-indigo-400) {
  --tw-text-opacity: 1;
  color: rgb(129 140 248 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-sky-100) {
  --tw-text-opacity: 1;
  color: rgb(224 242 254 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-sky-500) {
  --tw-text-opacity: 1;
  color: rgb(14 165 233 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-slate-100) {
  --tw-text-opacity: 1;
  color: rgb(241 245 249 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-slate-200) {
  --tw-text-opacity: 1;
  color: rgb(226 232 240 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-slate-300) {
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-slate-300\/20) {
  color: #cbd5e133;
}

:is(.dark .dark\:text-slate-400) {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-slate-500) {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-white) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

:is(.dark .dark\:shadow-lg) {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

:is(.dark .dark\:ring-green-500\/20) {
  --tw-ring-color: #22c55e33;
}

:is(.dark .dark\:ring-slate-100\/10) {
  --tw-ring-color: #f1f5f91a;
}

:is(.dark .dark\:brightness-90) {
  --tw-brightness: brightness(.9);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

:is(.dark .dark\:after\:bg-slate-900):after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}

:is(.dark .dark\:last\:border-slate-900:last-child) {
  --tw-border-opacity: 1;
  border-color: rgb(15 23 42 / var(--tw-border-opacity));
}

:is(.dark .dark\:hover\:border-gray-300:hover) {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

:is(.dark .dark\:hover\:border-slate-500:hover) {
  --tw-border-opacity: 1;
  border-color: rgb(100 116 139 / var(--tw-border-opacity));
}

:is(.dark .dark\:hover\:bg-red-600:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-slate-600:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-slate-700:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-slate-900:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-slate-900\/50:hover) {
  background-color: #0f172a80;
}

:is(.dark .dark\:hover\:bg-slate-900\/70:hover) {
  background-color: #0f172ab3;
}

:is(.dark .dark\:hover\:text-sky-100:hover) {
  --tw-text-opacity: 1;
  color: rgb(224 242 254 / var(--tw-text-opacity));
}

:is(.dark .dark\:hover\:text-sky-500:hover) {
  --tw-text-opacity: 1;
  color: rgb(14 165 233 / var(--tw-text-opacity));
}

:is(.dark .dark\:hover\:text-slate-100:hover) {
  --tw-text-opacity: 1;
  color: rgb(241 245 249 / var(--tw-text-opacity));
}

:is(.dark .dark\:hover\:text-slate-200:hover) {
  --tw-text-opacity: 1;
  color: rgb(226 232 240 / var(--tw-text-opacity));
}

:is(.dark .dark\:hover\:text-white:hover) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

:is(.dark .dark\:hover\:ring-slate-100\/20:hover) {
  --tw-ring-color: #f1f5f933;
}

:is(.dark .hover\:dark\:brightness-100):hover {
  --tw-brightness: brightness(1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

:is(.dark .group:hover .dark\:group-hover\:text-sky-100) {
  --tw-text-opacity: 1;
  color: rgb(224 242 254 / var(--tw-text-opacity));
}

:is(.dark .group:hover .dark\:group-hover\:text-slate-100) {
  --tw-text-opacity: 1;
  color: rgb(241 245 249 / var(--tw-text-opacity));
}

:is(.dark .group:hover .dark\:group-hover\:text-slate-200) {
  --tw-text-opacity: 1;
  color: rgb(226 232 240 / var(--tw-text-opacity));
}

:is(.dark .group:hover .dark\:group-hover\:text-slate-300) {
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity));
}

:is(.dark .group:hover .dark\:group-hover\:text-white) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

:is(.dark .peer:focus ~ .dark\:peer-focus\:ring-red-800) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(153 27 27 / var(--tw-ring-opacity));
}

@media (min-width: 640px) {
  .sm\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .sm\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .sm\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .sm\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .sm\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .sm\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .sm\:col-span-full {
    grid-column: 1 / -1;
  }

  .sm\:col-start-1 {
    grid-column-start: 1;
  }

  .sm\:col-start-10 {
    grid-column-start: 10;
  }

  .sm\:col-start-2 {
    grid-column-start: 2;
  }

  .sm\:col-start-3 {
    grid-column-start: 3;
  }

  .sm\:col-end-3 {
    grid-column-end: 3;
  }

  .sm\:col-end-4 {
    grid-column-end: 4;
  }

  .sm\:col-end-5 {
    grid-column-end: 5;
  }

  .sm\:m-8 {
    margin: 2rem;
  }

  .sm\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .sm\:mx-2 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .sm\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .sm\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .sm\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .sm\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .sm\:-mt-44 {
    margin-top: -11rem;
  }

  .sm\:mb-0 {
    margin-bottom: 0;
  }

  .sm\:ml-0 {
    margin-left: 0;
  }

  .sm\:ml-4 {
    margin-left: 1rem;
  }

  .sm\:ml-6 {
    margin-left: 1.5rem;
  }

  .sm\:mr-0 {
    margin-right: 0;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:mt-24 {
    margin-top: 6rem;
  }

  .sm\:block {
    display: block;
  }

  .sm\:inline {
    display: inline;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:grid {
    display: grid;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:h-6 {
    height: 1.5rem;
  }

  .sm\:max-w-2xl {
    max-width: 42rem;
  }

  .sm\:max-w-md {
    max-width: 28rem;
  }

  .sm\:max-w-none {
    max-width: none;
  }

  .sm\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .sm\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:items-start {
    align-items: flex-start;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:justify-start {
    justify-content: flex-start;
  }

  .sm\:justify-between {
    justify-content: space-between;
  }

  .sm\:gap-4 {
    gap: 1rem;
  }

  .sm\:gap-x-6 {
    column-gap: 1.5rem;
  }

  .sm\:gap-y-16 {
    row-gap: 4rem;
  }

  .sm\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.5rem * var(--tw-space-x-reverse));
    margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .sm\:divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }

  .sm\:divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-divide-opacity));
  }

  .sm\:overflow-hidden {
    overflow: hidden;
  }

  .sm\:rounded-3xl {
    border-radius: 1.5rem;
  }

  .sm\:rounded-lg {
    border-radius: .5rem;
  }

  .sm\:rounded-md {
    border-radius: .375rem;
  }

  .sm\:border-l {
    border-left-width: 1px;
  }

  .sm\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity));
  }

  .sm\:border-transparent {
    border-color: #0000;
  }

  .sm\:p-0 {
    padding: 0;
  }

  .sm\:p-6 {
    padding: 1.5rem;
  }

  .sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .sm\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .sm\:pl-16 {
    padding-left: 4rem;
  }

  .sm\:pl-20 {
    padding-left: 5rem;
  }

  .sm\:pl-6 {
    padding-left: 1.5rem;
  }

  .sm\:pr-0 {
    padding-right: 0;
  }

  .sm\:pt-0 {
    padding-top: 0;
  }

  .sm\:pt-16 {
    padding-top: 4rem;
  }

  .sm\:pt-52 {
    padding-top: 13rem;
  }

  .sm\:pt-80 {
    padding-top: 20rem;
  }

  .sm\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .sm\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .sm\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .sm\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .sm\:leading-6 {
    line-height: 1.5rem;
  }

  :is(.dark .sm\:dark\:border-slate-900) {
    --tw-border-opacity: 1;
    border-color: rgb(15 23 42 / var(--tw-border-opacity));
  }
}

@media (min-width: 768px) {
  .md\:sticky {
    position: sticky;
  }

  .md\:top-\[120px\] {
    top: 120px;
  }

  .md\:top-\[76px\] {
    top: 76px;
  }

  .md\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .md\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .md\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .md\:col-start-2 {
    grid-column-start: 2;
  }

  .md\:col-start-auto {
    grid-column-start: auto;
  }

  .md\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .md\:row-start-auto {
    grid-row-start: auto;
  }

  .md\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .md\:ml-6 {
    margin-left: 1.5rem;
  }

  .md\:mr-auto {
    margin-right: auto;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:block {
    display: block;
  }

  .md\:inline {
    display: inline;
  }

  .md\:flex {
    display: flex;
  }

  .md\:table-cell {
    display: table-cell;
  }

  .md\:grid {
    display: grid;
  }

  .md\:hidden {
    display: none;
  }

  .md\:min-h-\[60px\] {
    min-height: 60px;
  }

  .md\:w-1\/4 {
    width: 25%;
  }

  .md\:w-24 {
    width: 6rem;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:flex-1 {
    flex: 1;
  }

  .md\:cursor-grab {
    cursor: grab;
  }

  .md\:grid-flow-col {
    grid-auto-flow: column;
  }

  .md\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .md\:grid-cols-\[1fr\,61\.8\%\] {
    grid-template-columns: 1fr 61.8%;
  }

  .md\:grid-cols-\[1fr_min-content\] {
    grid-template-columns: 1fr min-content;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:place-items-center {
    place-items: center;
  }

  .md\:justify-end {
    justify-content: flex-end;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:gap-12 {
    gap: 3rem;
  }

  .md\:gap-6 {
    gap: 1.5rem;
  }

  .md\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .md\:whitespace-nowrap {
    white-space: nowrap;
  }

  .md\:border-l-0 {
    border-left-width: 0;
  }

  .md\:border-t-4 {
    border-top-width: 4px;
  }

  .md\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .md\:pb-0 {
    padding-bottom: 0;
  }

  .md\:pl-0 {
    padding-left: 0;
  }

  .md\:pt-0 {
    padding-top: 0;
  }

  .md\:pt-4 {
    padding-top: 1rem;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .lg\:fixed {
    position: fixed;
  }

  .lg\:inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .lg\:order-last {
    order: 9999;
  }

  .lg\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .lg\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .lg\:col-start-2 {
    grid-column-start: 2;
  }

  .lg\:col-end-3 {
    grid-column-end: 3;
  }

  .lg\:row-span-full {
    grid-row: 1 / -1;
  }

  .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .lg\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .lg\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:block {
    display: block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:table-cell {
    display: table-cell;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-8 {
    height: 2rem;
  }

  .lg\:w-72 {
    width: 18rem;
  }

  .lg\:w-8 {
    width: 2rem;
  }

  .lg\:w-\[1024px\] {
    width: 1024px;
  }

  .lg\:min-w-\[1024px\] {
    min-width: 1024px;
  }

  .lg\:max-w-4xl {
    max-width: 56rem;
  }

  .lg\:max-w-7xl {
    max-width: 80rem;
  }

  .lg\:max-w-none {
    max-width: none;
  }

  .lg\:flex-1 {
    flex: 1;
  }

  .lg\:flex-auto {
    flex: auto;
  }

  .lg\:flex-shrink-0, .lg\:shrink-0 {
    flex-shrink: 0;
  }

  .lg\:flex-grow {
    flex-grow: 1;
  }

  .lg\:cursor-zoom-in {
    cursor: zoom-in;
  }

  .lg\:cursor-zoom-out {
    cursor: zoom-out;
  }

  .lg\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .lg\:flex-col {
    flex-direction: column;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:justify-end {
    justify-content: flex-end;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:gap-x-10 {
    column-gap: 2.5rem;
  }

  .lg\:gap-x-5 {
    column-gap: 1.25rem;
  }

  .lg\:gap-x-8 {
    column-gap: 2rem;
  }

  .lg\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.75rem * var(--tw-space-x-reverse));
    margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .lg\:space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1;
  }

  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .lg\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .lg\:pl-0 {
    padding-left: 0;
  }

  .lg\:pl-72 {
    padding-left: 18rem;
  }

  .lg\:pr-4 {
    padding-right: 1rem;
  }

  .lg\:pt-0 {
    padding-top: 0;
  }

  .lg\:pt-36 {
    padding-top: 9rem;
  }

  .lg\:pt-4 {
    padding-top: 1rem;
  }

  .lg\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .lg\:shadow-none {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

@media (min-width: 1280px) {
  .xl\:order-none {
    order: 0;
  }

  .xl\:max-w-2xl {
    max-width: 42rem;
  }

  .xl\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .xl\:pt-80 {
    padding-top: 20rem;
  }
}

/*# sourceMappingURL=index.css.map */
