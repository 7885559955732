@import "tailwindcss/base.css";
@import "base/fonts.css";
@import "base/base.css";
@import "base/form.css";

@import "tailwindcss/components.css";
@import "components/button.css";
@import "components/confirmation.css";
@import "components/fade_truncate.css";
@import "components/finals_score.css";
@import "components/forms.css";
@import "components/label.css";
@import "components/lists.css";
@import "components/menu.css";
@import "components/messages.css";
@import "components/sortable_js.css";
@import "components/sticky_table.css";
@import "components/tab_navigation.css";

@import "tailwindcss/utilities.css";
@import "utilities/utilities.css";
